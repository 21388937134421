<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div id="cy" ref="cy"></div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import cytoscape from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';

cytoscape.use(coseBilkent);

import _ from 'lodash';

export default {
  name: 'Visualzation',
  components: {},
  props: {},
  data: () => {
    return {};
  },
  computed: {
    introspect() {
      return this.$d.introspect;
    },
  },
  methods: {
    getSnakeCase(name) {
      return _.snakeCase(name);
    },
    visualize(data) {
      console.log(data);

      var cy_elements = [];

      console.log(cy_elements);

      data['classes'].forEach(class_ => {
        cy_elements.push({
          group: 'nodes',
          data: {
            id: class_['class_name'],
            label: class_['class_name'],
          },
        });
      });

      data['relationships'].forEach(relationship => {
        var relationship_id = relationship['from']['class_name'] + ' - ' + relationship['relationship_name'];

        var source = relationship['from']['class_name'];
        var target = relationship['to']['class_name'];

        var has_source = cy_elements.find(function(element) {
          return element['data']['id'] == source;
        });
        var has_target = cy_elements.find(function(element) {
          return element['data']['id'] == target;
        });

        // console.log(has_source, has_target);

        if (has_source && has_target) {
          cy_elements.push({
            group: 'edges',
            data: {
              id: relationship_id,
              label: relationship['relationship_name'],
              source: source,
              target: target,
            },
          });
        } else {
          if (!has_source) {
            console.error('missing source: ', source);
          }

          if (!has_target) {
            console.error('missing target: ', target);
          }
        }
      });

      console.log(cy_elements);

      var cytoscape_config = {
        // container: document.getElementById('cy'), // container to render in
        container: this.$refs.cy, // container to render in

        layout: {
          name: 'cose-bilkent',
          // animate: true,

          // Whether to include labels in node dimensions. Useful for avoiding label overlap
          nodeDimensionsIncludeLabels: true,
          nodeRepulsion: 50000,
          idealEdgeLength: 500,
        },

        style: [
          {
            selector: 'node',
            style: {
              'background-color': '#000',
              opacity: 0.5,
              label: 'data(label)',
            },
          },
          {
            selector: ':parent',
            style: {
              'background-opacity': 0.333,
            },
          },
          {
            selector: 'edge',
            style: {
              width: 3,
              'line-color': '#888',
              opacity: 0.5,
              'target-arrow-color': '#888',
              'target-arrow-shape': 'triangle',
              label: 'data(label)', // maps to data.label
              'font-size': 12,
              'curve-style': 'unbundled-bezier',
              // 'control-point-distances': 120,
              // 'control-point-weights': 0.1
              'control-point-distances': 60,
              'control-point-weights': 0.1,
            },
          },
        ],

        // elements: ref: https://github.com/cytoscape/cytoscape.js-cose-bilkent/blob/master/demo-compound.html
      };

      cytoscape_config.elements = cy_elements;

      var cy = cytoscape(cytoscape_config);
    },
  },
  created() {},
};
</script>

<style>
#cy {
  width: 100wh;
  height: 100vh;
  display: block;
}
</style>
